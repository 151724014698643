import React from 'react';

import classes from './Overlay.module.css';

export default function Overlay({ Page, type }) {
	if (type === 'transparent') {
		return (
			<div className={classes.transparent}>
				{Page}
			</div>
		)
	} else {
		return (
			<div className={classes.usual}>
				{Page}
			</div>
		)
	}
}
