import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';

import classes from './Home.module.css';
import PulsingIcon from '../components/ui/PulsingIcon';

export default function Home({ LastUpdate }) {
	return (
		<div className={classes.homeOuter}>
			<div>
				<div className={classes.header}>
					<PulsingIcon />
					<span className={classes.title}><span className={classes.redColour}>Live</span>Transit</span>
				</div>
				<div className={classes.homeSearchHolder}>
					<TextField
						placeholder="Search"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchOutlined style={{ color: 'rgba(255, 255, 255, 0.4)' }} />
								</InputAdornment>
							),
						}}
						variant="outlined"
						fullWidth
					/>
				</div>
			</div>
			<div className={classes.footer}>
				<span className={classes.lastUpdate}>{LastUpdate}s</span> since last update
			</div>
		</div>
	)
}