import React from 'react';
import { Helmet } from 'react-helmet';

import config from './config.json';

export default function Head({ pageName, pageDescription = config.description }) {
	return (
		<Helmet>
			<title>{pageName ? `${pageName} | ${config.name}` : config.name}</title>
			<meta name="title" content={pageName ? `${pageName} | ${config.name}` : config.name} />
			<meta name="og:title" content={pageName ? `${pageName} | ${config.name}` : config.name} />
			<meta name="twitter:title" content={pageName ? `${pageName} | ${config.name}` : config.name} />
			<meta name="description" content={pageDescription} />
			<meta name="og:description" content={pageDescription} />
			<meta name="twitter:description" content={pageDescription} />
		</Helmet>
	)
}