import React from 'react';

import classes from './PulsingIcon.module.css';

export default function PulsingIcon() {
	return (
		<div className={classes.outer}>
			<div className={classes.back}></div>
			<div className={classes.centre}></div>
		</div>
	)
}