export default function Cameras(params) {
	return new Promise((resolve, reject) => {
		fetch(`https://livetransit.onrender.com/Camera`)
			.then((res) => res.json())
			.then((data) => {
				resolve(data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}