import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import Head from './Head';
// import Path from './pages/Path';
import Map from './components/ui/Map';
import Overlay from './components/layout/Overlay';
import Home from './pages/Home';
import { createTheme, ThemeProvider } from '@mui/material';

function App() {
    const [lastUpdate] = useState(3);

    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
        typography: {
            fontFamily: '"Inter", sans-serif',
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <Head />
                <Map />
                <Routes>
                    <Route path="/" element={<Overlay Page={<Home LastUpdate={lastUpdate} />} type="transparent" />} />
                    {/* <Route path="/path*" element={<Overlay Page={<Home />} type="transparent" />} /> */}
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;