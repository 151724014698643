import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { GeolocateControl } from 'mapbox-gl';

import video from '../../assets/icons/100h/video.png';
import Cameras from '../../functions/fetch/cameras';

mapboxgl.accessToken =
	'pk.eyJ1Ijoic2ViYXN0aWFuZG9lNSIsImEiOiJjbDQ4NTFvOW4wMzdoM2NyeTBxeGxxMHZ1In0.L6NRWtzgjriYkIEDApzrSQ';

export default function Map() {
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [lng] = useState(-0.128);
	const [lat] = useState(51.507);
	const [, setJamCamInfo] = useState(false);
	const [zoom] = useState(14);

	useEffect(() => {
		if (map.current) return;
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			projection: 'globe',
			style: 'mapbox://styles/mapbox/dark-v10',
			center: [lng, lat],
			zoom: zoom,
			attributionControl: false,
		});
		map.current.addControl(
			new mapboxgl.AttributionControl(),
			'bottom-right'
		);
		map.current.addControl(
			new GeolocateControl({
				positionOptions: {
					enableHighAccuracy: true,
				},
				trackUserLocation: true,
				showUserHeading: true,
			})
		);
		map.current.on('style.load', () => {
			map.current.setFog({
				color: 'rgb(186, 210, 235)',
				'high-color': 'rgb(36, 92, 223)',
				'horizon-blend': 0.02,
				'space-color': 'rgb(11, 11, 25)',
				'star-intensity': 0.6,
			});
		});

		map.current.on('load', () => {
			map.current.loadImage(video, (error, image) => {
				if (error) throw error;
				map.current.addImage('video', image);

				Cameras().then((geojson) => {
					setJamCamInfo(geojson);

					map.current.addSource('cameras', {
						type: 'geojson',
						data: geojson,
						cluster: true,
						clusterMaxZoom: 14,
						clusterRadius: 50,
					});

					map.current.addLayer({
						id: 'clusters',
						type: 'circle',
						source: 'cameras',
						filter: ['has', 'point_count'],
						paint: {
							'circle-color': '#da7135',
							'circle-stroke-width': 2,
							'circle-stroke-color': '#ffffff',
							'circle-radius': [
								'step',
								['get', 'point_count'],
								20,
								100,
								30,
								750,
								40,
							],
						},
					});

					map.current.addLayer({
						id: 'cluster-count',
						type: 'symbol',
						source: 'cameras',
						filter: ['has', 'point_count'],
						layout: {
							'text-field': ['get', 'point_count_abbreviated'],
							'text-font': [
								'DIN Offc Pro Medium',
								'Arial Unicode MS Bold',
							],
							'text-size': 16,
						},
						paint: {
							'text-color': '#ffffff',
						},
					});

					map.current.addLayer({
						id: 'unclustered-point',
						type: 'circle',
						source: 'cameras',
						filter: ['!', ['has', 'point_count']],
						paint: {
							'circle-radius': 20,
							'circle-stroke-width': 0,
							'circle-opacity': 0,
						},
					});

					map.current.addLayer({
						id: 'unclustered-image',
						type: 'symbol',
						source: 'cameras',
						filter: ['!', ['has', 'point_count']],
						layout: {
							'icon-image': 'video',
							'icon-size': 0.3,
						},
					});

					map.current.on('click', 'unclustered-point', (e) => {
						const coordinates =
							e.features[0].geometry.coordinates.slice();

						new mapboxgl.Popup()
							.setLngLat(coordinates)
							.setHTML(
								`<video autoplay loop class="camera-video"><source src="${
									e.features[0].properties.videoUrl
								}?${(Math.random() + 1)
									.toString(36)
									.substring(7)}" type="video/mp4"></video>
                                <span class="camera-title">${e.features[0].properties.cameraView}</span>`
							)
							.addTo(map.current);
					});

					map.current.on('click', 'clusters', (e) => {
						const coordinates =
							e.features[0].geometry.coordinates.slice();
						
						map.current.flyTo({
							center: coordinates,
							zoom: map.current.getZoom() + 2,
						});
					});

					map.current.on('mouseenter', 'clusters', () => {
						map.current.getCanvas().style.cursor = 'pointer';
					});
					map.current.on('mouseleave', 'clusters', () => {
						map.current.getCanvas().style.cursor = '';
					});

					map.current.on('mouseenter', 'unclustered-point', () => {
						map.current.getCanvas().style.cursor = 'pointer';
					});
					map.current.on('mouseleave', 'unclustered-point', () => {
						map.current.getCanvas().style.cursor = '';
					});
				});
			});
		});
	});

	return (
		<div
			ref={mapContainer}
			className="map-container"
		/>
	);
}
